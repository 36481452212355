<template>
  <Header
    ref="headerRef"
    type="RESTRICTIONS_OF_CRYPTO_ASSETS"
    :title="t('menus.Reports')"
    :showRegenerateButton="false"
    :updatedOn="updatedTime ?? ''"
  />
  <ReportsMenus
    ref="ReportsMenusRef"
    :params="params"
    :type="exportProgressStatusType.restrictions.reportValue"
    :title="t('project.nav.RestrictionsCryptoAssets')"
    @onChangDateTime="onChangDateTime"
  />

  <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.restrictions" :isMarginTop="true" />

  <div v-loading="loading" class="elv-reports-restriction-content">
    <FinancialStatement
      financial-type="restrictions"
      :params="params"
      :isShowRegeneratedInfo="ReportsMenusRef?.isShowRegeneratedInfo"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import ReportsMenus from '../../components/ReportsMenus.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import FinancialStatement from '../components/FinancialStatement.vue'
import ExportDataTaskHistoryInfo from '../components/ExportDataTaskHistoryInfo.vue'
import { exportProgressStatusType } from '@/config/global'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const { loading } = storeToRefs(reportStore)
const headerRef = useTemplateRef('headerRef')
const ReportsMenusRef = useTemplateRef<InstanceType<typeof ReportsMenus>>('ReportsMenusRef')

const updatedTime = computed(() => {
  return reportStore.financialData.updatedOn
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const params = computed(() => {
  return {
    period:
      ReportsMenusRef.value?.periodValue === 'date' ? 'DAY' : ReportsMenusRef.value?.periodValue.toLocaleUpperCase(),
    dateRange: ReportsMenusRef.value?.dateValue
  }
})

const onChangDateTime = async (data: object) => {
  await reportStore.financialsDataInit(entityId.value, 'restrictions', data)
}
</script>

<style lang="scss" scoped>
.elv-reports-restriction-content {
  min-height: 0px;
  flex: 1;
  position: relative;
}
</style>
